import React from 'react';
import Layout from "../components/Layout";
import {defineMessages, useIntl} from "react-intl";
import happyPeople from "../assets/images/dailyscrum/happy-people.svg";
import progressBoard from "../assets/images/dailyscrum/current-progress.svg";
import summaryBoard from "../assets/images/dailyscrum/summary-board.svg";
import line from "../assets/images/line-bottom-middle.svg";
import arrow from "../assets/images/dailyscrum/arrow.svg";
import arrowDashed from "../assets/images/arrow-dashed-right.svg";
import arrow3 from "../assets/images/dailyscrum/arrow2.svg";
import personTicket from "../assets/images/dailyscrum/person-ticket.svg";
import ticketsLinked from "../assets/images/dailyscrum/tickets-linked.svg";
import dailyReport from "../assets/images/dailyscrum/daily-report.svg";
import dailyReportBoard from "../assets/images/dailyscrum/daily-report-board.svg";
import zigzagLine from "../assets/images/scrumboard/zigzag-line.svg";
import robot from "../assets/images/dailyscrum/robot.svg";
import bell from "../assets/images/dailyscrum/bell.svg";
import pointingLine from "../assets/images/dailyscrum/pointing-line.svg";
import distributionBoard from "../assets/images/dailyscrum/work-distrubution.svg";
import distributionBoardMobile from "../assets/images/dailyscrum/work-distribution-mobile.svg";
import ticketPeople from "../assets/images/dailyscrum/ticket-people.svg";
import calendar from "../assets/images/dailyscrum/calendar.svg";
import manLaptop from "../assets/images/dailyscrum/man-laptop.svg";
import Img from "../components/Img";

const intro = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Dailyscrum'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Lead teams effectively'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship Daily views are the best ways to keep track of employees’ activity and productivity. Provide your managers with the tools they need to lead their teams effectively even when they work only on a remote basis. Boost team performance with Scrumship.'
    },
    people: {
        id: 'Alt.Text',
        defaultMessage: 'people talking about the project'
    },
});

const currentProgress = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Current Progress'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Employee\'s performance in one view'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship Current Progress view allows you to check each employee\'s performance in one view. Managers can follow the progress of task completion with ease while managing multiple projects.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Overview of all employees'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'We give you an overview of all managed employees and their tasks in all statuses upon current sprint.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Preview'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'You can track what tasks are currently in progress, in code review or testing for all your employees, and how many tasks are awaiting \n' + 'to be taken care of.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'current progress board in Scrumship team management software'
    },
    board2: {
        id: 'Alt.Text',
        defaultMessage: 'summary board in Scrumship team management app'
    }
});

const dailyUpdates = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Daily Updates'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Comprehensive report'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Reporting by employees of what has been done, what is currently being done and what problems are there, helps in achieving team goals faster. Updates are short and to the point.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Quick update'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Get an update in seconds. On Daily History View you can quickly check what each employee was working on during each work week.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Daily Bot'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'If someone reports a blocker, you can see it and respond straight away.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Daily Report'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'Track team engagement.\n' + 'Daily overview shows who’‘s shared an update.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'daily report in Scrumship'
    },
    board2: {
        id: 'Alt.Text',
        defaultMessage: 'daily report board in Scrumship app for team management'
    }
});

const workDistribution = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Work Distribution'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Overview of all employees'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'We give you an overview of each employee performance in projects and efficiency in different task statuses. Prioritize projects that support the company’s strategy and do more work that delivers measurable results.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Complete view of work done'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Work distribution view is a tool to help you plan, organize and manage your team\'s work. Get a complete view of work done on a monthly basis so team tackles the right project at the right time.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'work distribution board in Scrumship app for teams'
    },
});

const globalAlts =defineMessages({
    junior: {
        id: 'Alt.Text',
        defaultMessage: 'junior developer'
    },
    robot: {
        id: 'Alt.Text',
        defaultMessage: 'robot icon'
    },
    developer: {
        id: 'Alt.Text',
        defaultMessage: 'senior developer'
    },
    calendar: {
        id: 'Alt.Text',
        defaultMessage: 'page from calendar'
    },
});

const Dailyscrum = ({location}) => {
    const {formatMessage} = useIntl();
    return (
        <Layout pageName='dailyscrum-subpage' location={location}>
            <section className='intro'>
                <div className='container'>
                    <div className='section-one'>
                        <div className='text-decoration-container label'>
                            <h1 className='text-decoration'>{formatMessage(intro.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title title-no-line'><h3>{formatMessage(intro.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip1Text)}</p>
                                <div className='arrow-right'>
                                    <img src={arrowDashed} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='people'>
                            <div className='image-container full-width'>
                                <Img src={happyPeople} alt={formatMessage(intro.people)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='current-progress'>
                <div className='container'>
                    <div className='section-two'>
                        <div className='text-decoration-container label'>
                            <h1 className='text-decoration'>{formatMessage(currentProgress.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(currentProgress.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(currentProgress.protip1Text)}</p>
                                <div className='arrow-left'>
                                    <img src={arrow} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='progress-board'>
                            <div className='image-container full-width'>
                                <Img src={progressBoard} alt={formatMessage(currentProgress.board)}/>
                            </div>
                        </div>
                        <div className='advice-container advice1'>
                            <div className='advice-body'>
                                <h4>{formatMessage(currentProgress.advice1Header)}</h4>
                                <p>{formatMessage(currentProgress.advice1Text)}</p>
                            </div>
                            <div className='line-advice1'>
                                <img src={line} alt=""/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(currentProgress.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(currentProgress.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='summary-board'>
                            <div className='image-container full-width'>
                                <Img src={summaryBoard} alt={formatMessage(currentProgress.board2)}/>
                            </div>
                        </div>
                        <div className='person-ticket'>
                            <div className='image-container full-width'>
                                <Img src={personTicket} alt=""/>
                            </div>
                        </div>
                        <div className="tickets-linked">
                            <div className='image-container full-width'>
                                <Img src={ticketsLinked} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='daily-updates'>
                <div className='container'>
                    <div className='section-three'>
                        <div className='text-decoration-container label'>
                            <h1 className='text-decoration'>{formatMessage(dailyUpdates.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(dailyUpdates.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(dailyUpdates.protip1Text)}</p>
                            </div>
                            <div className='image-container full-width mt-xs'>
                                <Img src={zigzagLine} alt=""/>
                            </div>
                        </div>
                        <div className='daily-report'>
                            <div className='image-container full-width'>
                                <Img src={dailyReport} alt={formatMessage(dailyUpdates.board)}/>
                            </div>
                        </div>
                        <div className='advice-container advice1'>
                            <div className='advice-body'>
                                <h4>{formatMessage(dailyUpdates.advice1Header)}</h4>
                                <p>{formatMessage(dailyUpdates.advice1Text)}</p>
                            </div>
                            <div className='line-advice1'>
                                <div className='image-container full-width'>
                                    <Img src={line} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(dailyUpdates.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(dailyUpdates.protip2Text)}</p>
                            </div>
                            <div className='image-container full-width mt-xs'>
                                <Img src={zigzagLine} alt=""/>
                            </div>
                        </div>
                        <div className='daily-board'>
                            <div className='image-container full-width'>
                                <Img src={dailyReportBoard} alt={formatMessage(dailyUpdates.board2)}/>
                            </div>
                        </div>
                        <div className='advice-container advice2'>
                            <div className='advice-body'>
                                <h4>{formatMessage(dailyUpdates.advice2Header)}</h4>
                                <p>{formatMessage(dailyUpdates.advice2Text)}</p>
                            </div>
                            <div className='line-advice2a'>
                                <div className='image-container full-width'>
                                    <Img src={line} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='robot'>
                            <div className='image-container full-width'>
                                <Img src={robot} alt={formatMessage(globalAlts.robot)}/>
                            </div>
                        </div>
                        <div className='line-advice2b'>
                            <Img src={pointingLine} alt=""/>
                        </div>
                        <div className='bell'>
                            <Img src={bell} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='work-distribution'>
                <div className='container'>
                    <div className='section-four'>
                        <div className='text-decoration-container label'>
                            <h1 className='text-decoration'>{formatMessage(workDistribution.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(workDistribution.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(workDistribution.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='distribution-board desktop'>
                            <div className='image-container full-width'>
                                <Img src={distributionBoard} alt={formatMessage(workDistribution.board)}/>
                            </div>
                        </div>
                        <div className='distribution-board mobile'>
                            <div className='image-container full-width'>
                                <Img src={distributionBoardMobile} alt={formatMessage(workDistribution.board)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(workDistribution.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(workDistribution.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='arrow-bottom'>
                            <Img src={arrow3} alt=""/>
                        </div>
                        <div className='ticket'>
                            <div className='image-container full-width'>
                                <Img src={ticketPeople} alt=""/>
                            </div>
                        </div>
                        <div className='man'>
                            <div className='image-container full-width'>
                                <Img src={manLaptop} alt={formatMessage(globalAlts.developer)}/>
                            </div>
                        </div>
                        <div className='calendar'>
                            <div className='image-container full-width'>
                                <Img src={calendar} alt={formatMessage(globalAlts.calendar)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Dailyscrum;
